import moment from "moment"

import { checkIfAddressIsUnique } from "../utils/checkIfAddressIsUnique"
import { getSignedInUser } from "../../Auth/services/user"
import { checkIfUserAlreadyExists } from "../../Auth/services/signup"
import { isAccessCodeValid } from "../../Auth/services/permissions"
import { getUserAddresses, getUserData } from "../../Auth/services/signin"
import { isBrowser } from "../../../services/general"
import { uploadDocumentsToStorage } from "services/firebase/firebaseStorage"

import {
  GATSBY_WEBSITE_URL,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_USER_ROLE_ID,
  GATSBY_MAKE_CREATE_TELE_ENROLLMENT,
  GATSBY_ENV,
} from "gatsby-env-variables"

import firebase from "firebase"
import axios from "axios"

export const sendEnrollmentForm = async (config) => {
  let { errorCallback, callback, enrollmentData, dispatch } = config
  let {
    firstName,
    lastName,
    sex,
    birthday,
    mobileNumber,
    personalEmail,
    department,
    employeeNumber,
    emergencyContactName,
    emergencyContactEmail,
    emergencyContactNumber,
    emergencyContactRelationship,
    address,
    company,
    accessCode,
    employeeId,
    site,
    maxicareCardNumber,
  } = enrollmentData

  let emergencyContact = {
    name: emergencyContactName?.value || emergencyContactName,
    email: emergencyContactEmail?.value || emergencyContactEmail,
    mobileNumber: emergencyContactNumber?.value || emergencyContactNumber,
    email: emergencyContactEmail?.value || emergencyContactEmail,
    relationship:
      emergencyContactRelationship?.value || emergencyContactRelationship,
  }

  try {
    let { authUser, addresses, userData } = getSignedInUser()

    let email = authUser?.email
    let authUid = authUser?.uid
    let addressesId = addresses?.id || ""
    let addressesList = addresses?.addresses || []
    let userRoles =
      userData?.roles?.filter(
        (role) => role.subdomain !== GATSBY_WEBSITE_URL
      ) || []
    let userAllowedSubdomains =
      userData?.allowedSubdomains?.filter(
        (subdomain) => subdomain !== GATSBY_WEBSITE_URL
      ) || []

    if (!authUid) {
      userData = await checkIfUserAlreadyExists(authUser.email)
      authUid = userData?.authUid
    }

    let orgTradeName

    if (accessCode){
      orgTradeName = (await isAccessCodeValid(accessCode)).orgTradename
    }

    let organizationTradeName =
      (userData?.domain?.validity
        ? userData.domain.orgTradename
        : orgTradeName) || ""

    let documents = [employeeId?.front]
    let uploadedDocumentUrls

    if (documents?.length) {
      uploadedDocumentUrls = await uploadDocumentsToStorage({
        documents,
        path: `/${email}/employeeId`,
        docBaseName: `${moment().format("YYYYMMDD")}`,
        docType: "employeeid",
      })
    }

    // Add  address details
    let addressInformation = {
      primary: true,
      type: address?.addressType?.toLowerCase() || "home",
      streetAddress: address?.streetAddress,
      city: address?.city?.value || address?.city,
      province: address?.province?.value || address?.province,
      barangay: address?.barangay?.value || address?.barangay,
    }

    // Check if address exists
    let isAddressUnique = checkIfAddressIsUnique(
      addressInformation,
      addressesList
    )
    let finalAddressList = []
    if (isAddressUnique) {
      // Or remove primary from others, use new address as primary (since most recent enrollment --> most recent address)
      if (addressesList?.length > 0) {
        addressesList.forEach((address) => {
          address.primary = false
        })
      }
      finalAddressList = [...addressesList, { ...addressInformation }]
    } else {
      finalAddressList = [...addressesList]
    }

    let newAddress = await firebase
      .firestore()
      .collection("addresses")
      .add({ addresses: [...finalAddressList] })
    addressesId = newAddress.id

    let { month, date, year } = birthday

    const USER_DOCUMENT = {
      firstName: firstName || "",
      lastName: lastName || "",
      mobileNumber: mobileNumber || "",
      email: email || "",
      personalEmail: personalEmail || "",
      addresses: addressesId,
      personalEmail: personalEmail,
      birthday: {
        month: month.value || month,
        date: date.value || date,
        year: year.value || year,
      },
      company,
      emergencyContact: { ...emergencyContact },
      employeeNumber: employeeNumber,
      department: department,
      site: site?.label || site || "",
      maxicareCardNumber: maxicareCardNumber || "",
      sex,
      roles: [
        ...userRoles,
        {
          status: "active",
          subdomain: GATSBY_WEBSITE_URL,
          role: GATSBY_FIREBASE_USER_ROLE_ID,
          projectId: GATSBY_FIREBASE_PROJECT_ID,
        },
      ],
      allowedSubdomains: [...userAllowedSubdomains, GATSBY_WEBSITE_URL],
      accessCode,
      uploadedDocumentUrls,
      organizationTradeName,
    }
    if (!USER_DOCUMENT.accessCode){delete USER_DOCUMENT.accessCode} 

    // User data will always be null
    let firebaseUser
    if (userData?.id) {
      firebaseUser = await firebase
        .firestore()
        .collection("users")
        .doc(userData?.id)
        .update({ ...USER_DOCUMENT, authUid })
    } else {
      firebaseUser = await firebase
        .firestore()
        .collection("users")
        .add({ ...USER_DOCUMENT, authUid })
    }

    enrollmentData.email = email

    let newUserData = await getUserData({ authUid })
    let userAddresses = await getUserAddresses({ addressesId })

    if (isBrowser()) {
      sessionStorage.setItem("userData", JSON.stringify({ ...newUserData }))
      sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
    }
    // await axios.post(GATSBY_INTEGROMAT_SEND_ENROLLMENT_EMAIL_CONFIRMATION_URL, {
    //   ...newUserData,
    //   name: firstName,
    //   website: GATSBY_WEBSITE_URL,
    //   addressInformation,
    // })

    await axios.post(GATSBY_MAKE_CREATE_TELE_ENROLLMENT, {
      ...newUserData,
      ...USER_DOCUMENT,
      address: { ...addressInformation },
      firebaseUserId: firebaseUser?.id,
      website: "MGX Teleconsult Website",
      environment: GATSBY_ENV === "production" ? "production" : "develop",
    })

    await dispatch({ type: "RESET_DETAILS" })
    await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })

    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback()
  }
}
